import { loadMediaJs } from '@/assets/js/loadSudoku.js'
import $store from '@/store.js'
export function addMediaJs (displayType, page, callback) {
  loadMediaJs(() => {
    if (window.mediaObj) {
      mediaObj.platform = 'cc' // 集成平台
      mediaObj.page = page
      mediaObj.supportPlatform = $store.state.common.baseUrl.mediaControl.demandPlatform
      mediaObj.loginUrl = `${window.location.protocol}//${JSON.parse(window.localStorage.newCCInitConfig).urlInfo.tvuccUrl}` // 登出地址
      mediaObj.displayType = displayType
      try {
        SM.__proto__.socketRList(obj => {
          callback && callback(obj)
        })
      } catch (error) {}
    }
  })
}
/*
 * 需要根据传入的是Source还是R进行peerId的匹配
 * media的websocket返回值是以R为基准的
 */
export function handleRlist (shareData, nowRlist, type) {
  let liveRlist = []
  for (let item in nowRlist) {
    nowRlist[item].shareInfo = ''
    nowRlist[item].shareSize = 0
    if (nowRlist[item].status == '2') {
      liveRlist.push(nowRlist[item])
    }
  }
  if (!shareData || liveRlist.length <= 0) {
    return nowRlist
  }
  let socialInfo = shareData.SocialInfo
  let rList = shareData.RInfo
  if (socialInfo.length <= 0) {
    return nowRlist
  }
  rList.forEach(item => {
    for (let item1 in nowRlist) {
      if (type == 'S' && nowRlist[item1].type != 'R') {
        // 当Source为主体时,先将livePeerId置为选中的R
        nowRlist[item1].livePeerId = mediaObj.rId || nowRlist[item1].livePeerId
        if (
          nowRlist[item1].livePeerId == item.device.peerId &&
          nowRlist[item1].peerId == item.device.livePeerId
        ) {
          nowRlist[item1].shareInfo = item.shareInfo
          nowRlist[item1].shareSize = item.shareSize
        }
      } else {
        if (
          nowRlist[item1].peerId == item.device.peerId &&
          nowRlist[item1].livePeerId == item.device.livePeerId
        ) {
          nowRlist[item1].shareInfo = item.shareInfo
          nowRlist[item1].shareSize = item.shareSize
        }
      }
    }
  })
  return nowRlist
}

// 处理mySource界面数据
export function handleSource (sourceId, rId, flag, data) {
  let rList = []
  try {
    rList = JSON.parse(mediaObj.shareData)
  } catch (err) {}
  if (rList.length <= 0) {
    data.shareInfo = ''
    data.shareSize = 0
    return data
  }
  if (flag) {
    data.livePeerId = rId
  }
  rList.forEach(item => {
    if (item.device.peerId == rId && item.device.livePeerId == sourceId) {
      data.shareInfo = item.shareInfo
      data.shareSize = item.shareSize
    }
  })
  return data
}
