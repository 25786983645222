<template>
  <div class="bookmark-cascader">
    <div class="tree-btn clearfix">
      <div class="maxlength f_left">{{$t('lang.Bookmark')}}</div>
      <div
        class="f_left num click-hide"
        v-if="checkValue.length">({{bookmarkLength}})</div>
      <i :class="[visibleStatus ? 'el-icon-arrow-up': 'el-icon-arrow-top']"></i>

    </div>
    <el-cascader-panel
      v-if="fromPage == 'bk'"
      ref="bookmark"
      v-model="checkValue"
      :options="bookmarkList"
      :props="{ expandTrigger: 'hover', multiple: true, checkStrictly: true, emitPath: false }"
      collapse-tags
      @change="handleChange"
      @visible-change="visibleChange"
      popper-class="bookmarkList-cascader-panel"></el-cascader-panel>
    <el-cascader
      v-else
      ref="bookmark"
      v-model="checkValue"
      :options="bookmarkList"
      :props="{ expandTrigger: 'hover', multiple: true, checkStrictly: true, emitPath: false }"
      collapse-tags
      @change="handleChange"
      @visible-change="visibleChange"
      @blur="hideCascader"
      :popper-class="fromPage == 'al'? 'bookmarkList-cascader bookmarkList-cascader-al' : 'bookmarkList-cascader'"></el-cascader>
  </div>
</template>
<script>
import Bus from '@/assets/js/vueBus.js'
export default {
  props: {
    callback: Function,
    from: {
      type: Object,
      default: function () {
        return {
          Unclassified: false,
          Token: false
        }
      }
    },
    defaultKeys: {
      type: Object,
      default: function () {
        return {
          bookmardIds: [] // 为空 全选穿['all']
        }
      }
    },
    fromPage: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      checkValue: [], // 当前选中的bookmark的id数组
      checkValueList: [], // 用于更新视图
      bookmarkList: [], // 树形结构的bookmark
      allCheckList: [], // 所有选中的bookmark的id的数组
      allBookmarkArray: [], // 所有bookmark的id的数组
      visibleStatus: false,
      hasParent: false, // 是否有父账号

      oldCheckedList: []
    }
  },
  computed: {
    // 获取用户行为记录的id
    getSelectId () {
      let array = []
      if (this.defaultKeys.bookmardIds.includes('all')) { // 全选
        array = this.allBookmarkArray
      } else {
        array = this.defaultKeys.bookmardIds
      }
      array = array.filter(v => this.allBookmarkArray.includes(v))
      return array
    },
    accountType () {
      return this.$store.state.common.baseUserInfo.role
    },
    parentName () {
      return this.$store.state.common.baseUserInfo.parentName || ''
    },
    bookmarkLength () {
      let num
      if (this.hasParent) {
        let dis = 0
        if (this.checkValue.findIndex(v => v === '-1') >= 0) dis += 1
        if (this.checkValue.findIndex(v => v === '-2') >= 0) dis += 1
        num = this.checkValue.length - dis
      } else {
        num = this.checkValue.findIndex(v => v == 'all') >= 0 ? this.checkValue.length - 1 : this.checkValue.length
      }
      this.$store.state.common.bkBookmarkL = num
      return num
    }
  },
  created () {
    this.initQueryInfo()
    Bus.$on('langSeltSwitch', (val) => {
      if (!this.$i18n || this.fromPage != 'bk') return
      this.initQueryInfo()
    })
  },
  watch: {
    checkValue: function (val) {
      if (!this.hasParent && val.length == this.allBookmarkArray.length - 1) {
        if (!this.checkValue.includes('all')) {
          this.checkValue.push('all')
        }
      }
      if (this.hasParent && this.selfChildId.length > 0) {
        const notSelectSelf = this.selfChildId.filter(v => !val.includes(v))
        const selfAllIndex = val.findIndex(v => v === '-1')
        if (notSelectSelf.length > 0 && selfAllIndex >= 0) this.checkValue.splice(selfAllIndex, 1)
        if (notSelectSelf.length == 0 && selfAllIndex < 0) this.checkValue.unshift('-1')
      }
      if (this.hasParent && this.parentChildId.length > 0) {
        const notSelectparent = this.parentChildId.filter(v => !val.includes(v))
        const parentAllIndex = val.findIndex(v => v == '-2')
        if (notSelectparent.length > 0 && parentAllIndex >= 0) this.checkValue.splice(parentAllIndex, 1)
        if (notSelectparent.length == 0 && parentAllIndex < 0) this.checkValue.unshift('-2')
      }
    },
    getSelectId: function (val) {
      this.checkValue = val
    }
  },
  methods: {
    hideCascader () {
      // $('.bookmarkList-cascader').eq(0).css({ display: 'none' })
      this.visibleStatus = false
    },
    handleBookmark (bookmarkArr, flag) {
      if (!this.hasParent) {
        // 添加all
        bookmarkArr.unshift({
          bookmarkName: this.$t('lang.all'),
          id: 'all',
          children: []
        })
      }
      if (!this.$i18n) return
      if (this.from.Unclassified && flag) {
        bookmarkArr.push({
          bookmarkName: this.$t('lang.unClassified'),
          id: 'unClassified',
          children: []
        })
      }

      if (this.from.Token) {
        bookmarkArr.push({
          bookmarkName: 'Token',
          id: 'token',
          children: []
        })
      }
      return bookmarkArr
    },
    // 获取参数
    initQueryInfo () {
      // .get('/ccp/tvucc-user/bookmark/getAllChildBookmarkTree')
      this.axios
        .get('/ccp/tvucc-user/bookmark/getAllChildBookmarkTreeAndShare')
        .then(res => {
          if (res.data.errorCode == '0x0') {
            const result = res.data.result
            let selfResult = result.ownBookMarkList
            let parentResult = result.shareBookMarkList
            if ((!selfResult || !selfResult.length) && (!parentResult || !parentResult.length)) {
              this.handleCallBackData('hideBookmark')
              return
            }
            if (this.accountType == '4' && parentResult && parentResult.length > 0) {
              this.hasParent = true
              selfResult = this.handleBookmark(selfResult, true)
              parentResult = this.handleBookmark(parentResult, false)
              this.bookmarkList = [{
                id: '-1',
                bookmarkName: this.$t('lang.myBookmark'),
                children: selfResult
              }, {
                id: '-2',
                bookmarkName: this.parentName == '' ? this.$t('lang.parentBookmark') : `${this.parentName} ${this.$t('lang.otherBookmark')}`,
                children: parentResult
              }]
            } else {
              this.bookmarkList = this.handleBookmark(selfResult, true)
            }
            // 初始化获取选中的id
            this.allCheckList = JSON.parse(JSON.stringify(this.checkValue))

            this.initFormatDataList(this.bookmarkList)
            if (this.fromPage == 'bk') this.allCheckList = this.allBookmarkArray
          }
        }).catch(() => {
        })
    },
    // 点击节点
    handleChange (array) {
      this.checkValueList = JSON.parse(JSON.stringify(this.checkValue))
      // 先将checkValue赋值给checkValueList
      if (this.allCheckList.length < array.length) {
        const currentId = array.filter(v => !this.allCheckList.includes(v))
        // 增加筛选 需要子集全部勾选
        this.formatAddChildrenList(this.bookmarkList, currentId[0])
        // 直接push的checkValueList 无法更新视图 需要重新对checkValue进行赋值
      } else { // 取消筛选 需要子集全部取消
        // 获取当前点击的节点，并把其子集全部清空选中
        const currentId = this.allCheckList.filter(v => !array.includes(v))
        if (currentId[0] == 'all' || currentId.length == 0) {
          this.checkValueList = []
        } else {
          this.formatCancelDataList(this.bookmarkList, currentId[0])
        }
        // 过滤掉all的选项
        this.checkValueList = this.checkValueList.filter(v => v != 'all')
      }
      if (this.hasParent && this.selfChildId.length > 0) {
        const notSelectSelf = this.selfChildId.filter(v => !this.checkValueList.includes(v))
        const selfAllIndex = this.checkValueList.findIndex(v => v === '-1')
        if (notSelectSelf.length > 0 && selfAllIndex >= 0) this.checkValueList.splice(selfAllIndex, 1)
        if (notSelectSelf.length == 0 && selfAllIndex < 0) this.checkValueList.unshift('-1')
      }
      if (this.hasParent && this.parentChildId.length > 0) {
        const notSelectparent = this.parentChildId.filter(v => !this.checkValueList.includes(v))
        const parentAllIndex = this.checkValueList.findIndex(v => v == '-2')
        if (notSelectparent.length > 0 && parentAllIndex >= 0) this.checkValueList.splice(parentAllIndex, 1)
        if (notSelectparent.length == 0 && parentAllIndex < 0) this.checkValueList.unshift('-2')
      }
      this.checkValue = this.checkValueList
      this.allCheckList = JSON.parse(JSON.stringify(this.checkValue))
      this.handleCallBackData()
    },
    handleCallBackData (tag) {
      // 发送请求
      const ajaxData = {}
      // this.checkValue == this.allBookmarkArray.length -1 先执行点击，再去监听，此时没有all
      if (this.checkValue.includes('all') || (this.checkValue.length == this.allBookmarkArray.length - 1 && !this.hasParent) || tag === 'hideBookmark') {
        ajaxData.bookmardIds = 'all'
        if (this.from.Unclassified) {
          ajaxData.unClassified = true
        }
        if (this.from.Token) {
          ajaxData.token = true
        }
      } else {
        if (this.from.Unclassified) {
          if (this.checkValue.includes('unClassified')) {
            ajaxData.unClassified = true
          } else {
            ajaxData.unClassified = false
          }
        }

        if (this.from.Token) {
          if (this.checkValue.includes('token')) {
            ajaxData.token = true
          } else {
            ajaxData.token = false
          }
        }

        const idArray = this.checkValue.filter(v => v != 'unClassified').filter(val => val != 'token')
        ajaxData.bookmardIds = idArray.join(',')
      }
      this.callback(ajaxData, tag)
    },
    callBackShare (data) {
      this.callback(ajaxData)
    },
    // 展开 收起
    visibleChange (status) {
      this.visibleStatus = status
      if (status) { // 每次展开下拉列表时都需要查询，目的是更新标签（可bookmark出可添加删除标签）
        this.initQueryInfo()
      }
    },
    shareVisibleChange () {
      this.initQueryInfo()
    },
    initFormatDataList (data, ancestorId) {
      // 格式化初始数据  获取defaultCheckedkeys
      data.map((val, key) => {
        val.label = val.bookmarkName
        // val.label = val.id
        val.value = val.id
        val.children = val.children.length ? val.children : null
        if (!this.allBookmarkArray.includes(val.id)) {
          this.allBookmarkArray.push(val.id)
        }
        if (ancestorId && ancestorId === '-1' && val.id !== '-2') this.selfChildId.push(val.id)
        if (ancestorId && ancestorId === '-2' && val.id !== '-1') this.parentChildId.push(val.id)
        if (ancestorId == undefined) {
          ancestorId = val.id
          this.selfChildId = []
          this.parentChildId = []
        }
        ancestorId = ancestorId === '-1' && val.id === '-2' ? val.id : ancestorId
        // 如果有子组就递归调用
        val.children && this.initFormatDataList(val.children, ancestorId)
      })
    },
    formatAddChildrenList (data, id) {
      // 找到当前点击的那个一节点的所有数据
      data.map(v => {
        if (id == 'all') {
          // 选择全部，则勾选所有子集
          this.getAddCheckValue(data)
        } else {
          if (v.id == id) {
            v.children && this.getAddCheckValue(v.children)
          }
        }

        v.children && this.formatAddChildrenList(v.children, id)
      })
    },
    getAddCheckValue (data) {
      // 获取当前节点的所有子集id
      data.map(v => {
        // 直接push checkValue时 不更新视图
        if (!this.checkValueList.includes(v.id)) {
          this.checkValueList.push(v.id)
        }
        v.children && this.getAddCheckValue(v.children)
      })
    },
    formatCancelDataList (data, id) {
      data.map(v => {
        if (v.id == id) {
          v.children && this.getCancelCheckValue(v.children)
        }
        v.children && this.formatCancelDataList(v.children, id)
      })
    },
    getCancelCheckValue (data) {
      data.map(v => {
        this.checkValueList = this.checkValueList.filter(val => val != v.id)
        v.children && this.getCancelCheckValue(v.children)
      })
    }
  }
}
</script>
<style lang="less" scoped>
::v-deep .el-cascader {
  line-height: 32px;
}
::v-deep .el-cascader__tags {
  display: none;
}
.bookmark-cascader {
  position: relative;
  height: 100%;
  ::v-deep .el-cascader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    .el-input__icon {
      display: none;
    }
    .el-input__inner {
      background: transparent;
      border: none;
      height: 32px !important;
      &::-webkit-input-placeholder {
        color: transparent;
      }
      &::after {
        border-color: #33ab4f !important;
        height: 7px;
        left: 5px;
        top: 1px;
        border-width: 1px;
      }
    }
  }
}
.tree-btn {
  background: #444;
  border: none;
  border-radius: 5px;
  color: #fff;
  padding: 0 20px;
  cursor: pointer;
  position: relative;
  text-align: left;
  i {
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
  }
}
</style>
