<template>
  <!--创建EXT跟grid配对的按钮-->
  <el-dialog
    :title="$t('lang.addExtSource')"
    :visible.sync="data.show"
    width="620px"
    :center="true"
    :close-on-click-modal="false"
    class="add-source-dialog"
    @close="cancel">
    <div class="content scrollbar">
      <div class="item">
        <span>{{$t('lang.Type')}}</span>
        <div>
          <select-menu
            :data="typeData"
            :noTranslate="true"
            :callback="change"
            selectVal="Ext"></select-menu>
        </div>
      </div>
      <!--如果创建类型为gridToken时下拉框会换样式-->
      <div
        class="type"
        v-if="type != 'GridToken'">
        <div class="item">
          <span>URL</span>
          <el-input
            v-model.trim="url"
            autocomplete="off"
            size="small"
            :maxlength="255"></el-input>
        </div>
        <div class="item">
          <span>{{$t('lang.name')}}</span>
          <el-input
            v-model.trim="name"
            autocomplete="off"
            size="small"
            :maxlength="125"></el-input>
        </div>
      </div>
      <div
        class="type"
        v-if="type == 'GridToken'">
        <div class="item">
          <span>{{$t('lang.tokenCode')}}</span>
          <el-input
            v-model.trim="code"
            autocomplete="off"
            :placeholder="$t('lang.tokenCodeTips')"
            size="small"></el-input>
        </div>
        <div class="item">
          <span>{{$t('lang.self')}}</span>
          <el-input
            v-model="self"
            autocomplete="off"
            size="small"
            :placeholder="$t('lang.selfTips')"></el-input>
        </div>
        <div class="item">
          <!--设备列表展示-->
          <span>{{$t('lang.chooseRecevice')}}</span>
          <el-select
            v-model="rPeerIdArr"
            multiple
            filterable
            remote
            :placeholder="$t('lang.chooseReceviceTips')"
            v-scroll="scrollDate">
            <div class="selectRlist">
              <el-option
                v-for="(item,index) in allRList"
                :key="index"
                :label="item.label"
                :value="item.value">
              </el-option>
            </div>

          </el-select>
        </div>
      </div>
    </div>
    <span
      slot="footer"
      class="dialog-footer">
      <!--     保存，取消按钮-->
      <el-button
        class="save"
        v-repeat-click="{event:save}">{{$t('lang.Save')}}</el-button>
      <el-button
        class="cancel"
        @click="cancel">{{$t('lang.cancel')}}</el-button>
    </span>
  </el-dialog>
</template>
<script>
import SelectMenu from '@/components/SelectMenu'
import { newHomeAnalyze } from '@/assets/js/googleAnalyze/newHome.js'
import Outil from '@/assets/js/utils.js'
import 'vuex'
export default {
  props: {
    data: Object
  },
  data () {
    return {
      scrollDate: {
        onScroll: this.onScroll, // 滚动条滚动时触发这个方法
        distance: 10, // 触发的距离
        directive: '.el-select-dropdown .el-select-dropdown__wrap'
      },
      page: 1, // 默认展示的页数
      size: 30, // 默认一页多少个设备
      onMore: false,
      loading: false,
      url: '',
      name: '',
      type: 'Ext',
      code: '',
      self: '',
      isHaveMarketPermission: true,
      typeData: [ // 下拉列表的内容
        { value: 'Ext', label: 'Ext' },
        { value: 'SMPTE2022', label: 'SMPTE2022' },
        { value: 'YouTube', label: 'YouTube' }
        // {value: 'GridToken', label: 'Grid Token',disabled: true} //注意下面控制权限的地方 直接使用的是 typeData[3]
      ],
      allRList: [], // 所有设备的数组
      rPeerIdArr: [], // 所有设备pId的数组
      oldCCUrl: window.localStorage.getItem('oldCCUrl'), // 取老cc的接口地址
      host: window.location.protocol
    }
  },
  mounted () {
    // 展示的接口默认参数 页数 列表数 与搜索内容
    const params = {
      pageNum: 1,
      pageSize: 30,
      condition: ''
    }
    // 请求所有设备的接口
    this.axios.get('/ccp/tvucc-user/userDevice/pageAllUserRNoStatus', params).then(res => {
      const allRList = res.data.result.list
      for (let i = 0; i < allRList.length; i++) {
        let obj = {}
        obj.value = allRList[i].peerId
        obj.label = allRList[i].name
        this.allRList.push(obj)
      }
    }).catch(() => {})
  },
  components: {
    SelectMenu // 选项组件
  },
  created () {
    this.getFeature()
  },
  methods: {
    getFeature () { // 获取用户权限
      const feature = this.$store.state.common.baseUserInfo.userFeatures
      if (feature.length != 0) {
        feature.map((v, i) => {
          if (v.feature == 'gridToken') {
            if (v.value == 1) {
              const gridPermation = {
                value: 'GridToken',
                label: 'Grid Token'
              }
              this.typeData.push(gridPermation)
            }
          }
        })
      }
    },
    change (val) {
      newHomeAnalyze(`newHomemySourceAddExtType${val}`, this.$route.path) // 谷歌统计
      this.type = val
      this.clear() // 清空选项框的方法
    },
    save () {
      newHomeAnalyze('newHomemySourceSaveExt', this.$route.path) // 谷歌统计
      const routeName = this.$route.name

      if (this.type != 'GridToken') {
        if (!this.url) {
          this.$message.error(this.$t('lang.urlError'))
          return
        }
        if (!this.name || (this.name && this.name.length < 3)) {
          this.$message.error(this.$t('lang.extSourceNameTips'))
          return
        }
        const data = {
          type: this.type,
          name: this.name,
          url: this.url
        }
        this.axios.post('/ccp/tvucc-user/ext/addExt', data).then(res => {
          if (res.data.errorCode == '0x0' && !res.data.result) {
            this.$message({
              type: 'success',
              message: this.$t('lang.success')
            })
            this.$emit('queryList', routeName)
            this.clear()
            this.data.show = false
          } else if (res.data.errorCode == '0x0' && res.data.result) {
            this.$message({
              type: 'info',
              message: this.$t('lang.sameExtName', { name: res.data.result })
            })
          } else {
            Outil.ToTip(res.data.errorCode, 'error', res.data.errorInfo)
          }
        }).catch(() => {})
      } else {
        if (!this.code) {
          this.$message.error(this.$t('lang.extSourceTokenTips'))
          return
        }
        const willAddRIds = this.rPeerIdArr.join(';')
        const data = {
          type: '123'
        }
        this.axios.post('/ccp/tvucc-device/token/optGridToken?tokenCode=' + this.code + '&description=' + encodeURIComponent(this.self) + '&willAddRIds=' + willAddRIds, data).then(res => {
          const result = JSON.parse(res.data.result)
          if (result.errorCode == '0x0') {
            this.$message({
              type: 'success',
              message: this.$t('lang.success')
            })
            this.$emit('queryList', routeName)
            this.clear()
            this.data.show = false
          } else {
            this.$message.error(this.$t('lang.failed'))
          }
        }).catch(() => {})
      }
    },
    clear () {
      this.url = ''
      this.name = ''
      this.code = ''
      this.self = ''
    },
    cancel () {
      this.clear()
      this.data.show = false
      newHomeAnalyze('newHomemySourceAddExtClose', this.$route.path) // 谷歌统计
    }
  }

}
</script>
<style lang="less" scoped>
.add-source-dialog {
  .scrollbar {
    overflow-x: hidden;
  }
  .content {
    text-align: left;
    font-size: 16px;
    max-height: 55vh;
    overflow-y: auto;
    .item {
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
      }
      span {
        display: block;
        margin-bottom: 5px;
      }
    }

    ::v-deep .el-input .el-input__inner {
      border: 1px solid #444;
      background: #444;
      height: 46px;
      line-height: 46px;

      &::-webkit-input-placeholder {
        color: #666;
      }
    }

    ::v-deep .select-box {
      width: 100%;

      .el-input__inner {
        border: 1px solid #444;
        background: #444;
        height: 46px;
        line-height: 46px;
      }
    }

    ::v-deep .el-select {
      width: 100%;

      .el-select__input {
        color: #fff;
      }
    }

    ::v-deep .selectRlist {
      width: 100%;
      height: 300px;
      overflow-y: auto;
    }
  }
}
</style>
