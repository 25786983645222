<template>
  <div class="multipe-select-box">
    <div class="title">
      {{ title }}{{ activeNum && (!ifR) ? `(${activeNum})` : "" }}
    </div>
    <el-select
      :disabled="ifR"
      v-model="checkList"
      multiple
      @change="change"
      @visible-change="visibleChange"
      popper-class="multipe-select-menu"
      placeholder=" "
      :popper-append-to-body="appendToBody">
      <el-option
        v-for="(item, index) in dataList"
        :key="item[keyArray[0]]"
        :label="useDefault ? $t(`lang.${item.label}`) : item[keyArray[1]]"
        :value="item[keyArray[0]]"
        :class="item.color">
        <hr
          class="seperateLine"
          v-if="hasOnGoing && index == dataList.length - 1"/>
        <el-checkbox
          v-if="!noCheckBox"
          v-model="item[keyArray[2]]"></el-checkbox>
        <slot
          name="deviceIcon"
          :iconVal="item.icon"></slot>
        <div
          class="name"
          :class="judgeClass(item)">{{ useDefault ? $t(`lang.${item.label}`) : item[keyArray[1]] }}</div>
      </el-option>
    </el-select>
  </div>
</template>
<script>
/**
 * data 下拉的数据 必传项 数组类型
 * data = [{
 *     value: 'online', //可配置传参的字段,可与后台给的字段一致
 *     label: '在线', //显示的名称
 *     isChecked:true, //是否选中 可用来记录用户行为 选中设置为true即可
 *     color:'green' //选中框的颜色  不传默认为白色线框和背景绿色对勾样式
 * }]
 * title 下拉框的标题
 * callback 筛选后的回调
 */
import { nameColor } from '@/config.js'

export default {
  props: {
    data: {
      type: Array, // 下拉数据
      required: true
    },
    title: String, // 下拉框的标题
    noCheckBox: {
      type: Boolean,
      default: false
    },
    hasOnGoing: {
      type: Boolean,
      default: false
    },
    ifR: {
      type: Boolean,
      default: false
    },
    callback: {
      type: Function, // 选中后的回调
      default: function () { }
    },
    typeVal: {
      type: String,
      default: ''
    },
    keyArray: {
      type: Array,
      default: function () {
        return ['value', 'label', 'isChecked']
      }
    },
    useDefault: {
      type: Boolean,
      default: true
    },
    returnDataInfo: {
      type: Boolean,
      default: false
    },
    appendToBody: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isHaveAll: false,
      checkList: [],
      allType: [],
      allBtn: false,
      dataList: [],
      lastList: []
    }
  },
  computed: {
    activeNum: function () {
      let num = 0
      this.dataList.map((v) => {
        if (v.isChecked) {
          num += 1
        }
      })
      if (this.isHaveAll) {
        if (this.dataList[0].isChecked) {
          num -= 1
        }
      }
      return num
    }
  },
  watch: {
    data: {
      handler: function (val) {
        this.dataList = JSON.parse(JSON.stringify(val))
        this.formatData()
      },
      immediate: true,
      deep: true
    }
  },
  created () {
    this.dataList = JSON.parse(JSON.stringify(this.data))
    this.isHaveAll = !!(this.dataList[0] && this.dataList[0].value == 'all')
    this.formatData()
  },
  methods: {
    formatData () {
      if (this.isHaveAll && this.activeNum == this.dataList.length - 1) this.dataList[0].isChecked = true
      // 针对MySource界面添加了onGoingEvent
      if (this.hasOnGoing && this.isHaveAll && this.activeNum == this.dataList.length - 2 && !this.dataList[this.dataList.length - 1].isChecked) this.dataList[0].isChecked = true
      this.checkList = []
      this.allType = []
      this.dataList.map((v) => {
        if (v.isChecked) this.checkList.push(v[this.keyArray[0]])
        this.allType.push(v[this.keyArray[0]])
      })
      this.lastList = JSON.parse(JSON.stringify(this.checkList))
    },
    visibleChange (val) {
      if (val) this.$emit('openList')
    },
    change (list) {
      this.allBtn = false
      let isSelectAll = false
      const operateOnGoing = this.hasOnGoing && (this.lastList.includes('onGoingEvent') && !list.includes('onGoingEvent') || !this.lastList.includes('onGoingEvent') && list.includes('onGoingEvent'))
      // 如果是MySource界面有onGoingEvent的情况，需要对选择all做特殊处理
      if (!operateOnGoing) {
        if ((this.lastList.includes('all') && !list.includes('all')) || (!this.lastList.includes('all') && list.includes('all'))) {
          isSelectAll = true
        }
      }
      this.lastList = list
      const dataList = typeof this.dataList === 'string' ? JSON.parse(JSON.stringify(this.dataList)) : this.dataList
      let lastData = []
      let checkAllStatus
      if (this.isHaveAll) checkAllStatus = this.dataList[0].isChecked
      dataList.map((v) => {
        if (v.isChecked) {
          lastData.push(v[this.keyArray[0]])
        }
      })
      list = list.filter((v) => v != 'all')
      lastData = lastData.filter((v) => v != 'all')
      const changeData = this.getArrDifference(lastData, list)
      this.dataList.map((v) => {
        // 选其他
        changeData.map((value) => {
          if (v[this.keyArray[0]] == value) {
            v[this.keyArray[2]] = !v.isChecked
            const obj = this.dataList[0]
            if (this.isHaveAll) {
              if (v.isChecked) {
                // 有onGoingEvent和没有时处理方式不一样
                if (this.hasOnGoing) {
                  if ((operateOnGoing || !operateOnGoing && this.checkList.includes('onGoingEvent')) && this.dataList.length == this.activeNum + 1 ||
                  !operateOnGoing && !this.checkList.includes('onGoingEvent') && this.dataList.length == this.activeNum + 2) {
                    obj.isChecked = true
                    if (!this.checkList.includes('all')) this.checkList.push('all')
                    this.$set(this.dataList, 0, obj)
                  }
                } else {
                  if (this.dataList.length == this.activeNum + 1) {
                    // 选中all
                    obj.isChecked = true
                    if (!this.checkList.includes('all')) this.checkList.push('all')
                    this.$set(this.dataList, 0, obj)
                  }
                }
              } else {
                this.checkList = this.checkList.filter((v) => v != 'all')
                if (!operateOnGoing) obj.isChecked = false // 只有不操作onGoingEvent的时候才取消all的勾选
                this.$set(this.dataList, 0, obj)
              }
            }
          }
        })
        if (!changeData.length && this.hasOnGoing) {
          // 选了全部
          this.allBtn = true
          this.checkList = this.checkList.filter((m) => m != 'all')
          if (checkAllStatus) {
            v.isChecked = v.value != 'onGoingEvent' ? false : v.isChecked
            this.checkList = this.checkList.includes('onGoingEvent') ? ['onGoingEvent'] : []
          } else {
            v.isChecked = v.value != 'onGoingEvent' ? true : v.isChecked
            const arr = JSON.parse(JSON.stringify(this.allType))
            const index = this.allType.indexOf('onGoingEvent')
            if (lastData.indexOf('onGoingEvent') >= 0) {
              this.checkList = JSON.parse(JSON.stringify(this.allType))
            } else {
              arr.splice(index, 1)
              this.checkList = arr
            }
          }
        } else if (!changeData.length && !this.hasOnGoing) {
          // 选了全部
          this.allBtn = true
          this.checkList = this.checkList.filter((v) => v != 'all')
          if (checkAllStatus) {
            v.isChecked = false
            this.checkList = []
          } else {
            v.isChecked = true
            this.checkList = this.allType
          }
        }
      })
      // 处理数据
      const obj = {}
      let dataInfo = {}
      this.dataList.map((v) => {
        if (v[this.keyArray[0]] != 'all') {
          obj[v[this.keyArray[0]]] = v.isChecked
          if (this.returnDataInfo) {
            dataInfo = v
          }
        }
      })

      /*
      * 筛选后的回调
      * 传参目的做埋点统计  list选中的每一项，typeVal当前点击的item
      */
      if (this.allBtn) {
        this.callback(
          this.returnDataInfo ? dataInfo : obj,
          { all: !checkAllStatus },
          list,
          this.typeVal,
          isSelectAll
        )
      } else {
        this.callback(
          this.returnDataInfo ? dataInfo : obj,
          '',
          list,
          this.typeVal,
          isSelectAll
        )
      }
    },
    getArrDifference (arr1, arr2) {
      // 过滤数组
      return arr1.concat(arr2).filter(function (v, i, arr) {
        return arr.indexOf(v) === arr.lastIndexOf(v)
      })
    },
    judgeClass (item) {
      const obj = {}
      let _str = ''
      obj.noPadding = this.noCheckBox
      _str = nameColor[item.status]
      obj[_str] = true
      return obj
    }
  }
}
</script>
<style lang="less" scoped>
::v-deep .el-select-dropdown__wrap {
  max-height: 320px !important;
}
.multipe-select-box {
  position: relative;
  height: 32px;
  line-height: 32px;
  .seperateLine {
    margin: 0;
    border-top: 1px solid #b9b7b7;
    width: calc(100% + 60px);
    margin-left: -20px;
  }
  .title {
    background: #444;
    height: 100%;
    line-height: inherit;
    text-align: left;
    padding: 0 20px;
    position: relative;
    border-radius: 5px;
    i {
      position: absolute;
      right: 5px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  ::v-deep .el-select {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    .el-select__tags {
      display: none;
    }
    .el-input__inner {
      border: none;
      background: none;
      color: transparent;
      height: inherit !important;
      line-height: inherit !important;
      &::-webkit-input-placeholder {
        color: transparent;
      }
    }
  }
  ::v-deep .el-select-dropdown {
    text-align: left;
    left: 0px !important;
    position: absolute !important;
    .el-checkbox {
      left: 15px;
      margin-top: 0;
    }
  }
}
span {
  display: inline-block;
  width: 100px;
  text-align: right;
}
::v-deep .el-dropdown {
  .el-button {
    text-align: left;
    &.el-button--primary {
      width: 100%;
      background-color: #444;
      border: none;
      border-radius: 5px;
      color: #fff;
      padding: 6px 20px;
    }
  }
  .el-icon--right {
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
  }
}
</style>
