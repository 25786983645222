<template>
  <div class="select-box">
    <!-- <i class="el-icon-caret-bottom"></i> -->
    <el-select
      v-model="val"
      :disabled="disabled"
      :clearable="clearable"
      :placeholder="placeholder"
      :filterable="filterable"
      @change="change"
      :popper-append-to-body="false"
      @visible-change="visibleChange">
      <el-option
        v-for="item in data"
        :key="item.value"
        :label="noTranslate ? item.label : $t(`lang.${item.label}`)"
        :value="item.value"
        :disabled="item.disabled"></el-option>
    </el-select>
  </div>
</template>
<script>
/**
 * data 列表数据，必须包含label value [{label:'summer',value:2}], label用来显示， value选中的值
 * placeholder 文案
 * callback 筛选后的回调
 * selectVal 默认选中值,可用来记录用户行为,默认不传为空
 * filterable 是否可搜索 默认不传为false
 * noTranslate 是否需要翻译 默认需要
 *
 */
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    },
    visible: {
      // 下拉显示隐藏
      type: Function,
      default: function () { }
    },
    callback: {
      // 筛选后的回调
      type: Function,
      default: function () { }
    },
    placeholder: String,
    data: Array,
    selectVal: [String, Number],
    filterable: {
      type: Boolean,
      default: false
    },
    noTranslate: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      val: this.selectVal || ''
    }
  },
  watch: {
    selectVal: function (val) {
      this.val = val
    }
  },
  created () {
  },
  methods: {
    change () {
      this.callback(this.val)
    },
    visibleChange (val) {
      this.visible(val)
    }
  }
}
</script>
<style lang="less" scoped>
.m-b-20 {
  margin-bottom: 20px;
  text-align: left;
  span {
    display: inline-block;
    width: 100px;
    text-align: right;
  }
}
.select-box {
  ::v-deep .el-select {
    width: 100%;
  }
  ::v-deep .el-select-dropdown {
    text-align: left;
    left: 0 !important;
    position: absolute !important;
  }
  // ::v-deep .el-select-dropdown__wrap{
  //   max-height: 266px;
  // }
}
</style>
