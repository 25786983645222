<template>
  <div class="search-input">
    <el-input
      :placeholder="placeholder"
      v-model="val"
      autocomplete="off"
      size="small"
      @change="search()"
      v-filter-special-char="{changeVal,regular:this.regular}">
    </el-input>
    <i
      class="el-icon-search"
      @click="search()"></i>
  </div>
</template>
<script>
/**
 * value 记录用户行为 默认值(不传默认为空)
 * placeholder 文案
 * callback 搜索完的回调 必传参数
 * 组件支持enter和点击图标的搜索
 * regular 通过传入的正则表达式来过滤字符,默认过滤空格
 */
export default {
  props: {
    value: {
      type: String,
      default: ''
    },
    placeholder: String,
    callback: {
      type: Function,
      required: true
    },
    regular: {
      type: RegExp,
      default: function () {
        return /\s+/g
      }
    }
  },
  data () {
    return {
      val: this.value
      // regular:''
    }
  },
  watch: {
    value: function (val) {
      this.val = val
    }
  },
  methods: {
    changeVal (val) {
      this.val = this.val ? val : ''
    },
    search (val) {
      // 默认不需要传参数
      if (val === undefined) {
        this.callback(this.val)
      } else {
        // 此处用来清除数据或者手动听过其它按钮更改输入框参数
        this.val = val

        this.callback(val)
      }
    }
  }
}
</script>
<style lang="less" scoped>
.search-input {
  position: relative;
  ::v-deep .el-input__inner {
    padding: 0 45px 0 15px;
    color: #dbdbdb;
    &::-webkit-input-placeholder {
      color: #666;
    }
  }
  .el-icon-search {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    cursor: pointer;
  }
  input {
    color: #333;
  }
}
</style>
