<template>
  <div
    class="top clearfix homeSearchTop"
    :class="{
      homeMapSearchTop: $route.name == 'NSourceMap',
      mapMaxHeight: filtersToggle,
    }">
    <div class="left searchBox">
      <!-- 搜索 -->
      <div class="box width200 input-box left">
        <search-input
          :placeholder="$t('lang.searchTText')"
          :callback="search"
          :value="filter.condition"></search-input>
      </div>
      <div
        class="box left filters-toggle"
        @click="filterSectionToggle"
        v-show="!filterSection">
        <i class="icon iconfont">&#xe69c;</i> {{ $t("lang.filters") }}
      </div>
      <span
        class="addBtn iconfont sourceAddIcon addBtnMediaNormal"
        @click="addSource"
        v-if="!filterSection"></span>
    </div>
    <div
      class="filters-section left mediaFilterOne"
      v-show="(!filterSection && filtersToggle) || filterSection">
      <!-- status -->
      <div class="box set">
        <drop-menu-select
          :data="statusData"
          :typeVal="'status'"
          :title="$t('lang.status')"
          :callback="changeFilter"></drop-menu-select>
      </div>
      <!-- bookmark -->
      <div
        class="box"
        @click="openBookmarkList"
        v-if="$route.name != 'NSourceMap'">
        <book-mark
          :callback="changeFilter"
          :from="{ Unclassified: true }"
          :defaultKeys="defaultBookmark"></book-mark>
      </div>
      <!-- type -->
      <div class="box set">
        <drop-menu-select
          :data="typeData"
          :typeVal="'type'"
          :title="$t('lang.Type')"
          :callback="changeFilter">
          <template
            slot-scope="iconVal"
            slot="deviceIcon">
            <span
              v-html="iconVal.iconVal"
              class="iconfont deviceIcon"></span>
          </template>
        </drop-menu-select>
      </div>
      <!-- 排序 -->
      <div
        class="box last-select"
        v-show="$route.name != 'NSourceMap'">
        <select-menu
          :data="selectBySort"
          :callback="changeSort"
          :selectVal="selectSort"></select-menu>
      </div>
      <span
        class="addBtn iconfont sourceAddIcon addBtnMediaNormal"
        @click="addSource"
        v-if="filterSection && $route.name != 'NSourceMap'"></span>
    </div>
    <div
      class="info map"
      v-show="$route.name == 'NSourceMap'">
      {{ $t("lang.refreshInterval") }}
      <div class="fresh box">
        <select-menu
          :data="freshData"
          :noTranslate="true"
          :callback="changeFresh"
          :selectVal="freshTime"></select-menu>
      </div>
      <i class="red"></i>
      <i class="green"></i>
      <i class="gray"></i>
      <span class="xy">{{ $t('lang.coordinate') }}(
        <span class="red">{{ mapNum.livingPosition }}</span>
        /<span class="green">{{ mapNum.onlinePosition }}</span>
        /<span class="gray">{{ mapNum.offlinePosition }}</span>
        <span v-show="mapNum.noPosition>0">&nbsp;{{ $t('lang.ReceiverError') }}: {{mapNum.noPosition}}</span>)
      </span>
    </div>
    <div class="right rightFl pageNormal">
      <!-- 分页 -->
      <div
        class="info icon"
        v-show="$route.name != 'NSourceMap'">
        <Pagination
          @current-change="handleCurrentChange"
          :current-page="filter.pageNum"
          :pageSizes="[10, 15, 20, 50, 100]"
          :pageSize="filter.pageSize"
          :total="total"
          @size-change="handleSizeChange"
          layout="prev, pageF, next, pageL, nowSize, jumper">
        </Pagination>
      </div>
      <!-- 列表切换 -->
      <div
        class="pageIcon"
        :class="{ mapPage: $route.name == 'NSourceMap' }">
        <div class="info icon">
          <i
            class="iconfont sourceListIcon"
            :class="{ tabActive: $route.name == 'NSourceContent' }"
            @click="jump('NSourceContent')"></i>
        </div>
        <div class="info icon">
          <i
            class="iconfont sourceInfoIcon"
            :class="{ tabActive: $route.name == 'NSourceList' }"
            @click="jump('NSourceList', 'jump')"></i>
        </div>
        <div class="info icon">
          <i
            class="iconfont i-map"
            :class="{ tabActive: $route.name == 'NSourceMap' }"
            @click="jump('NSourceMap')"></i>
        </div>
      </div>
    </div>
    <!-- addSource -->
    <add-source
      v-if="add.show"
      :data="add"
      @queryList="queryInfo"></add-source>
  </div>
</template>
<script>
import Bus from '@/assets/js/vueBus.js'
import { mapState } from 'vuex'
import { MySourceStatus, MySourceType, SelectBySorts } from '@/config'
import SearchInput from '@/components/Search'
import BookMark from '@/components/bookMark'
import SelectMenu from '@/components/SelectMenu'
import Pagination from '@/components/Pagination'
import AddSource from './dialog/addSource'
import DropMenuSelect from '@/components/dropMenuSelect'
import { newHomeAnalyze } from '@/assets/js/googleAnalyze/newHome.js'

export default {
  name: 'sourceTop',
  components: {
    SearchInput,
    BookMark,
    SelectMenu,
    Pagination,
    AddSource,
    DropMenuSelect
  },
  props: {
    query: Function,
    mapNum: {
      type: Object,
      default: () => {
        return {
          livingPosition: 0,
          totalPosition: 0
        }
      }
    }
  },
  computed: mapState({
    // 映射
    userBehaviors: (state) => (state.userBehaviors ? state.userBehaviors : {})
  }),
  data () {
    return {
      statusData: JSON.parse(JSON.stringify(MySourceStatus)),
      typeData: JSON.parse(JSON.stringify(MySourceType)),
      selectBySort: SelectBySorts,
      pageSize: 20,
      total: 0,
      freshTime: 60,
      freshData: [
        // map更新数据
        { label: 60, value: 60 },
        { label: 30, value: 30 },
        { label: 20, value: 20 },
        { label: 10, value: 10 }
      ],
      filter: {
        // 筛选过滤参数
        living: true,
        online: true,
        offline: true,
        noCamera: true,
        pack: true,
        anywhere: true,
        gridPack: true,
        gridSdi: true,
        ext: true,
        pageNum: 1,
        pageSize: 20,
        condition: '',
        bookmardIds: 'all',
        unClassified: true,
        token: true,
        onlineFirst: true,
        liveFirst: false,
        globalGrid: true,
        gridViaToken: true
      },
      selectSort: '', // 下拉排序
      defaultBookmark: {
        // 默认bookmark选中的值l
        bookmardIds: ['all'],
        token: true,
        unClassified: true
      },
      add: {
        // add ext
        show: false
      },
      show: false,
      lastFilterStatus: [],
      lastFilterType: [],
      showFilter: false,
      filterSection: false,
      filtersToggle: true
    }
  },
  created () {
    if (this.$route.name == 'NSourceMap') {
      const extIndex = this.typeData.findIndex(v => v.value == 'ext')
      if (extIndex >= 0) {
        this.typeData.splice(extIndex, 1)
      }
    }
    this.queryInfo(this.$route.name, 'init')
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
    Bus.$on('updateHome', () => {
      this.initInfo()
    })
  },
  destroyed () {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    // 搜索函数
    search (val) {
      newHomeAnalyze('newHomemySourceSearch', this.$route.path) // 谷歌统计
      this.filter.pageNum = 1
      this.filter.condition = val
      this.queryInfo('', 'filter')
      // this.queryInfo(this.$route.name,'init');
    },

    openBookmarkList () {
      newHomeAnalyze('newHomemySourceBookmarkOpen', this.$route.path) // 谷歌统计
    },
    // 谷歌统计处理
    handleAnalyze (allVal, list, lastArr, type) {
      if (allVal != '' && allVal.all === true) {
        newHomeAnalyze(`newHome${type}AllSelected`, this.$route.path) // 谷歌统计
        this[lastArr] =
          type == 'mySourceStatus' ? ['online', 'noCamera', 'living', 'offline'] : [
            'pack',
            'anywhere',
            'gridPack',
            'gridSdi',
            'ext',
            'gridViaToken',
            'globalGrid'
          ]
        return
      }
      if (allVal != '' && allVal.all === false) {
        newHomeAnalyze(`newHome${type}AllUnselect`, this.$route.path) // 谷歌统计
        this[lastArr] = []
        return
      }
      list.map((v, i) => {
        if (!this[lastArr].includes(v)) { newHomeAnalyze(`newHome${type}${v}Selected`, this.$route.path) } // 谷歌统计
      })
      this[lastArr].map((v, i) => {
        if (!list.includes(v)) { newHomeAnalyze(`newHome${type}${v}Unselect`, this.$route.path) } // 谷歌统计
      })
      this[lastArr] = list
    },
    // 筛选
    changeFilter (obj, allVal, list, type) {
      // 谷歌统计
      if (list && type == 'status') {
        this.handleAnalyze(allVal, list, 'lastFilterStatus', 'mySourceStatus')
      }
      if (list && type == 'type') {
        this.handleAnalyze(allVal, list, 'lastFilterType', 'mySourceType')
      }
      Object.keys(obj).map((v) => {
        // this.filter 过滤参数对象
        this.filter[v] = obj[v]
      })
      this.filter.pageNum = 1
      this.queryInfo()
      // this.$route.name != 'NSourceMap' && this.queryInfo() // 筛选后查询接口
    },
    // 排序
    changeSort (val) {
      newHomeAnalyze(`newHomemySourceSort${val}`, this.$route.path) // 谷歌统计
      this.filter.pageNum = 1
      this.filter.onlineFirst = val == 'onlineFirst'
      this.filter.liveFirst = val == 'liveFirst'
      this.$route.name != 'NSourceMap' && this.queryInfo()
    },
    // 分页
    handleCurrentChange (val) {
      // console.info('page',val);
      this.filter.pageNum = val
      this.queryInfo()
    },
    handleSizeChange (val) {
      this.filter.pageSize = val
      this.filter.pageNum = 1
      this.queryInfo()
    },
    // 更改刷新时间
    changeFresh (val) {
      this.freshTime = val
      this.queryInfo(this.$route.name, 'frequency')
    },
    // 路由跳转
    jump (val, type) {
      newHomeAnalyze(`newHomemySource${val}`, this.$route.path) // 谷歌统计
      if (val == this.$route.name) return
      const routeName = val || this.$route.name
      this.$router.push({ name: routeName })
    },
    // 地图所需数据
    emitMapData (flag) {
      const obj = {
        query: this.filter,
        freshTime: this.freshTime,
        queryFlag: flag
      }
      this.$emit('filterMap', obj)
      this.show = true
    },
    initInfo () {
      const routeName = this.$route.name
      /*
        * 此处的routeName 用以前的SourceContent先用
        * this.axios.post("/ccp/tvucc-user/userDevice/mySourceWithInit",{ apiType: 'SourceContent', timeout:15000 }).then(res=>{
        */
      this.axios.post('/ccp/tvucc-user/userDevice/homeWithInit', {
        // apiType: routeName,
        apiType: 'SourceContent'
      }).then((res) => {
        if (res.data.errorCode == '0x0' && res.data.errorInfo == 'Success') {
          this.showFilter = true
          /* 处理右边source的数据 */
          const result = res.data.result.homeSource
          const userBehaviors = result.param
          const list = result.content
          // status
          if (userBehaviors.living) this.lastFilterStatus.push('living')
          if (userBehaviors.noCamera) this.lastFilterStatus.push('noCamera')
          if (userBehaviors.offline) this.lastFilterStatus.push('offline')
          if (userBehaviors.online) this.lastFilterStatus.push('online')
          // type
          if (userBehaviors.pack) this.lastFilterType.push('pack')
          if (userBehaviors.anywhere) this.lastFilterType.push('anywhere')
          if (userBehaviors.gridPack) this.lastFilterType.push('gridPack')
          if (userBehaviors.gridSdi) this.lastFilterType.push('gridSdi')
          if (userBehaviors.ext) this.lastFilterType.push('ext')
          if (userBehaviors.gridViaToken) { this.lastFilterType.push('gridViaToken') }
          if (userBehaviors.globalGrid) { this.lastFilterType.push('globalGrid') }
          // 初始化筛选条件
          if (userBehaviors) {
            Object.keys(this.filter).map((v) => {
              Object.keys(userBehaviors).map((K) => {
                if (v == K) {
                  this.filter[v] = userBehaviors[K]
                }
                if (v == 'token') {
                  this.filter[v] = this.filter.anywhere
                }
                if (v == 'globalGrid' || v == 'gridViaToken') {
                  this.filter[v] = this.filter.gridSdi || this.filter.gridPack
                }
              })
            })
          }
          // 用来处理筛选选中的状态
          this.statusData.map((v, k) => {
            const obj = v
            obj.isChecked = this.filter[v.value] || false
            this.$set(this.statusData, k, obj)
          })
          this.typeData.map((v, k) => {
            // v.isChecked = this.filter[v.value];
            const obj = v
            obj.isChecked = this.filter[v.value] || false
            this.$set(this.typeData, k, obj)
          })
          this.selectSort = this.$options.filters.FORMATSORT(this.filter)
          // bookmark
          this.defaultBookmark.bookmardIds = this.filter.bookmardIds ? this.filter.bookmardIds == 'all' ? ['all'] : this.filter.bookmardIds.split(',') : []
          this.filter.unClassified &&
              this.defaultBookmark.bookmardIds.push('unClassified')
          this.filter.token && this.defaultBookmark.bookmardIds.push('token')
          this.show = true
          // pageSize
          this.filter.pageSize = 20
          /* 处理右边source的数据结束 */
          /* 处理左边R的数据结束 */
          const receiverData = res.data.result.pageR
          receiverData.isInit = true // 拼装是初始化数据
          Bus.$emit('sendReceiverListData', receiverData) // 监听发送websocket
          /* 处理左边R的数据结束 */
          // 根据不同的typeApi 做不同的操作
          if (routeName == 'NSourceContent' || routeName == 'NSourceList') {
            this.total = list.total || 0
            this.query(list)
            this.$emit('sendMySourceSockect', list) // 监听发送websocket
            Bus.$emit('changeFilter', true)
          }
          if (routeName == 'NSourceMap') {
            const flag = false
            this.emitMapData(flag)
          }
        }
      }).catch(() => {
        Bus.$emit('changeFilter', true)
        Bus.$emit('sendReceiverListData', true)
      })
    },
    queryInfo (val, type) {
      // 查询数据
      const routeName = val || this.$route.name
      let result = {}
      Bus.$emit('changeFilter', false) // 监听状态
      // if (!(routeName == 'NSourceMap' && type == 'filter')) {
      //   Bus.$emit('changeFilter', false) // 监听状态
      // }
      if (routeName == 'NSourceContent' || routeName == 'NSourceList') {
        if (type == 'init') {
          this.initInfo()
        } else {
          const data = JSON.parse(JSON.stringify(this.filter))
          data.token = data.anywhere
          data.globalGrid = data.gridSdi || data.gridPack
          data.gridViaToken = data.gridSdi || data.gridPack
          if (routeName == 'NSourceList') {
            data.battery = true
            data.bookmarks = true
          }
          this.axios.post('/ccp/tvucc-user/userDevice/homePageUserSource', data, {
            contentType: 'application/json;charset=UTF-8',
            isCancelToken: true
          }).then((res) => {
            if (res.data.errorCode == '0x0' && res.data.errorInfo == 'Success') {
              result = res.data.result
              result.list.forEach(v => {
                if (v.type == 'T' && v.platform) v.version = v.releaseStr || v.version
              })
              this.total = result.total || 0
              this.query(result)
              this.$emit('sendMySourceSockect', result) // 监听发送websocket
              Bus.$emit('changeFilter', true)
            }
          }).catch(() => {})
        }
      }

      if (routeName == 'NSourceMap') {
        if (type == 'init') {
          this.initInfo()
        } else {
          const flag = type != 'frequency'
          this.emitMapData(flag)
        }
      }
    },
    addSource () {
      // 添加外部视频源
      this.add.show = true
      newHomeAnalyze('newHomemySourceAddExtOpen', this.$route.path) // 谷歌统计
    },
    filterSectionToggle () {
      this.filtersToggle = !this.filtersToggle
    },
    handleResize () {
      if (document.body.clientWidth > 1769) {
        this.filterSection = true
        this.filtersToggle = true
      }
      if (document.body.clientWidth < 1770) {
        this.filterSection = false
        this.filtersToggle = false
        $('.bookmarkList-cascader').hide()
      }
    }
  },
  watch: {
    $route (to, from) {
      // 切换及后退会触发路由变化
      this.queryInfo(to.name, 'frequency')
    }
  }
}
</script>
<style lang="less" scoped>
.homeSearchTop {
  position: relative;
  padding: 37px 0.5% 24px 0;

  .searchBox {
    height: auto;
  }
  .deviceIcon {
    width: 12px;
    margin: 0 8px 0 15px;
    font-size: 16px;
    float: left;
  }
  .filters-section {
    position: absolute;
    left: 230px;

    .box {
      width: 160px;
      margin-right: 15px;
      position: relative;
      float: left;

      &.set {
        height: 32px;
        line-height: 32px;
      }

      &.width200 {
        margin-right: 20px;
      }

      ::v-deep .el-dropdown {
        width: 100%;
        height: 32px;
      }

      ::v-deep .el-dropdown .el-button.el-button--primary {
        padding: 9px 20px;
      }

      ::v-deep .tree-btn {
        padding: 6px 20px;
      }

      ::v-deep .tree {
        top: 36px;
      }
    }
  }

  .sourceAddIcon {
    float: left;
    display: inline-block;
  }

  .filters-toggle {
    border: 1px solid #666666 !important;
    padding: 0 20px;
    border-radius: 5px;
    cursor: pointer;
    color: #999999;
    width: 100px !important;
    height: 32px;
    line-height: 32px;
  }

  .rightFl {
    .paginationBox {
      margin-top: 2px;
    }

    .pageIcon {
      height: 40px;
      //   width: 106px;
      display: inline-block;
      margin-top: 3px;
      float: right;

      .icon {
        margin-left: 10px;
        color: #999;
        float: left;

        .sourceListIcon:before {
          content: '\E684';
          font-size: 24px;
          cursor: pointer;
        }

        .sourceInfoIcon:before {
          content: '\E701';
          font-size: 24px;
          cursor: pointer;
        }

        .i-map {
          margin-top: -2px;
        }

        i {
          position: relative;
          top: -3px;

          &:before {
            font-size: 28px;
          }

          &.tabActive {
            color: #dbdbdb;
          }
        }
      }
    }
  }

  .map {
    display: inline-block;
    text-align: right;
    position: absolute;
    right: 110px;

    i {
      display: inline-block;
      width: 12px;
      height: 12px;
      border-radius: 12px;
      margin-left: 3px;

      &.red {
        background: #d03f39;
      }

      &.green {
        background: #33ab4f;
      }

      &.gray {
        background: #999;
      }
    }

    .fresh {
      width: 70px;
      display: inline-block;
      margin-right: 5px;
    }

    .xy {
      margin-left: 5px;
      color: #999;
      .red {
        color: #d03f39;
      }
      .green {
        color: #33ab4f;
      }
      .gray {
        color: #999;
      }
    }
  }

  .sourceAddIcon {
    margin: 3px 0 0 10px;
  }

  .sourceAddIcon:before {
    content: '\e602';
    font-size: 18px;
    cursor: pointer;
  }

  .box {
    ::v-deep .select-box {
      .el-input__inner {
        height: 32px;
        line-height: 32px;
      }
    }
  }

  .left {
    .input-box {
      margin-right: 15px;
    }
  }

  .right {
    .info {
      float: left;
    }
  }
}

.homeMapSearchTop.homeSearchTop {
  .pageNormal {
    position: absolute;
    right: 0;
  }
}

.mapMaxHeight.homeMapSearchTop.homeSearchTop {
  .filters-section {
    margin-top: 0;
  }
}

@media (max-width: 1770px) {
  .homeSearchTop {
    .filters-section {
      width: 100%;
      max-width: 100%;
      position: relative;
      left: 0;
      margin-top: 5px;
    }
  }

  .mapMaxHeight.homeMapSearchTop.homeSearchTop {
    height: auto;

    .filters-section {
      margin-top: 10px;
    }
  }
}

@media (max-width: 1195px) {
  .homeSearchTop.homeMapSearchTop {
    .searchBox {
      margin-bottom: 10px;
    }

    .map {
      display: inline-block;
      text-align: left;
      position: static;
      float: left;
      left: 0;
    }

    .pageNormal {
      position: static;
      display: inline-block;
      width: auto;
      text-align: left;
    }
  }
}

@media (max-width: 1100px) {
  .homeSearchTop {
    height: 120px;

    .filters-section {
      max-width: calc(100% - 120px);

      .box {
        margin-bottom: 5px;
      }
    }

    .rightFl {
      width: 100%;
      margin-top: 5px;
    }
  }
}

@media (max-width: 960px) {
  .homeSearchTop {
    .searchBox {
      display: block;
    }

    .filters-section {
      margin-top: 5px;
      width: 100%;
    }
  }
}

@media (max-width: 760px) {
  ::v-deep .tree-btn {
    i {
      right: 10px !important;
    }
  }

  .homeSearchTop {
    height: 195px;
    padding: 37px 0.5% 0 0;

    .filters-section {
      width: 100% !important;
      max-width: 100%;
    }

    .searchBox {
      width: 100%;

      .input-box {
        width: 100%;
        margin-bottom: 10px;
      }
    }
  }

  .homeSearchTop.mapMaxHeight {
    .filters-section {
      width: 100%;
      max-width: 100%;

      .box {
        width: 100%;

        ::v-deep .multipe-select-box .el-select {
          width: 100%;
        }
      }

      .last-select {
        .select-box {
          width: 100%;
        }
      }
    }
  }

  .homeSearchTop.homeMapSearchTop {
    height: auto;

    .map {
      width: 100%;
    }

    .pageNormal {
      width: 100%;
      text-align: left;
      float: none;
      display: inline-block;
    }
  }
}
</style>
