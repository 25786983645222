<template>
  <div
    class="content mediaContent"
    v-if="$route.name=='NSourceMap'">
    <source-top
      :query="queryInfo"
      :mapNum="mapNum"
      @filterMap="changeMapTimers"></source-top>
    <div
      class="listBox listBoxMedia"
      v-if="show">
      <router-view
        :data="result"
        :sourceObj="result.list"
        :mapQueryObj="mapQueryObj"
        @mapNumChange="getMapNum"></router-view>
    </div>
  </div>
  <div
    class="content mediaContent"
    v-else>
    <source-top
      :query="queryInfo"
      :mapNum="mapNum"
      @filterMap="changeMapTimers"
      @sendMySourceSockect='sendMySourceSockect'></source-top>
    <div
      class="listBox listBoxMedia"
      v-loading='!show'
      element-loading-background="rgba(0, 0, 0, 0.8)">
      <div v-if="show">
        <router-view
          :data="result"
          :sourceObj="allSourceData"
          :mapQueryObj="mapQueryObj"
          :imgUrlTime='imgUrlTime'
          :initDeviceList='initDeviceList'
          :page='"nhm"'
          @mapNumChange="getMapNum"></router-view>
      </div>
    </div>
  </div>
</template>
<script>
import SourceTop from './sourceTop' // 顶部搜索部分
import { mapState, mapMutations } from 'vuex'
import Bus from '@/assets/js/vueBus.js'
import { addMediaJs, handleRlist } from '@/assets/js/shareCommon.js'

export default {
  components: {
    SourceTop
  },
  data () {
    return {
      loading: false,
      result: {
        allSourceData: []
      },
      allSourceData: [],
      show: false,
      peerIds: '', // 所有的source id
      btn: false,
      routeName: '',
      mapNum: {},
      mapQueryObj: {},
      pageMsgWebsocket: {},
      liveRids: [],
      shareData: [],
      ifOpenMediaService: this.$store.state.common.baseUrl.mediaControl.home || false,
      curDeviceList: [], // 当前的设备列表
      imgUrlTime: {
        current: {},
        last: {}
      },
      imgTimer: null,
      initDeviceList: [],
      initCurrentInfoSocket: true
    }
  },
  created () {
    Bus.$on('changeFilter', data => {
      this.show = data
    })
    Bus.$on('loadingConfigT', data => {
      this.loading = data
    })
    Bus.$off('openListWebsocket')
    Bus.$on('openListWebsocket', () => {
      if (this.liveRids) this.sendMsgWebsocket('ReceiverData', '2152857600', '101', 1000)
    })
    // media集成相关——加载公共js获取shareInfo
    if (this.ifOpenMediaService) {
      addMediaJs('S', 'mySource', (shareData) => {
        this.shareData = shareData
        mediaObj.shareData = JSON.stringify(shareData.RInfo)
      })
    }
  },
  computed: {
    ...mapState({
      State: state => state,
      pageRequest: state => state.websocket.pageRequest,
      pageRWebsocket: state => state.websocket.pageRWebsocket
    })
  },
  watch: {
    liveRids (val) {
      // 监听receiverlist的变化 用以停止currentRInfo 的websocket 防止数据获取不到
      if (val.length > 0) {
        // 开启websocket
        this.updateSocketParams('receiverData', '2152857600', '101')
        if (this.initCurrentInfoSocket) {
          this.sendMsgWebsocket('ReceiverData', '2152857600', '101', 1000) // 获取err and quality信息
          // this.updateParams()
          this.initCurrentInfoSocket = false
        }
      } else {
        // 关闭websocket
        this.initCurrentInfoSocket = true
        this.stopupdateParams()
      }
    }
  },
  methods: {
    ...mapMutations('common', [
      'DEVICELIST_RECEIVERDATADATA'
    ]),
    // 更新websocket参数
    updateParams () {
      if (this.liveRids.length <= 0) {
        // 正在live的R为空时，清空store中的数据
        this.DEVICELIST_RECEIVERDATADATA([])
      }
      this.updateSocketParams('receiverData', '2152857600', '101')
    },
    stopupdateParams () {
      // 停止获取数据
      if (this.$createWebsocket.pageRWebsocket) {
        this.$createWebsocket.pageRWebsocket('stop', ['receiverData']) // 停止websocket
      }
    },
    // 获取设备实时信息
    sendMsgWebsocket (type, categoryId, operationType, time) {
      if (this.$createWebsocket.pageRWebsocket) {
        this.$createWebsocket.pageRWebsocket('stop', type) // 停止websocket
      }
      let typeVal = ''
      if (type != 'VolumeChart') { typeVal = `${type[0].toLowerCase()}${type.slice(1, type.length)}` }
      this.pageRWebsocket.send({
        message: {
          categoryId: categoryId,
          operationType: operationType,
          rid: this.liveRids.join(',')
        },
        time: time || 5000,
        key: typeVal || type,
        success: (res) => {
          this.updateDeviceDetailData(
            `deviceList${type}Data`,
            `DEVICELIST_${type.toUpperCase()}DATA`,
            res
          )
        }
      })
    },
    // 更新websocket参数
    updateSocketParams (type, categoryId, operationType) {
      const message = {
        categoryId: categoryId,
        operationType: operationType,
        rid: this.liveRids.join(',')
      }
      if (this.pageRWebsocket.sendMsgs && this.pageRWebsocket.sendMsgs[type]) {
        this.pageRWebsocket.sendMsgs[type].message = message
      }
    },
    // 更新音柱和设备详情信息
    updateDeviceDetailData (deviceData, fn, data) {
      let deviceDetailArr = []
      let deviceDetailIdArr = []
      this.$store.state.common[deviceData].map((v, i) => {
        if (this.liveRids.includes(v.rid)) {
          deviceDetailArr.push(v)
          deviceDetailIdArr.push(v.rid)
        }
      })
      if (deviceDetailIdArr.includes(data.rid)) {
        deviceDetailArr[deviceDetailIdArr.indexOf(data.rid)] = data
      } else {
        deviceDetailArr.push(data)
      }
      this[fn](deviceDetailArr)
    },
    sendMySourceSockect (data) { // 但获取到当前R的id的时候 创建websocket
      this.initDeviceList = data && data.list
      this.imgUrlTime = {
        current: {},
        last: {}
      }
      if (data && !this.btn) {
        this.sendWebsocket(data)
        if (this.imgTimer) {
          clearInterval(this.imgTimer)
        }
        this.imgTimer = setInterval(() => {
          this.thumbnailUpdate()
        }, 5000)
      }
    },
    changeMapTimers (obj) {
      this.mapQueryObj = obj
      this.show = true
    },
    getMapNum (obj) {
      this.mapNum = obj
    },
    queryInfo (val) {
      this.result = val
      this.allSourceData = []
      const peerIds = []
      val.list.map((item, index) => {
        item.lastLiveName = ''
        peerIds.push(item.peerId)
        if (item.type == 'T' && item.platform) item.version = item.releaseStr || item.version
        this.allSourceData[item.peerId] = item
      })
      this.result.allSourceData = this.allSourceData
      this.result = Object.assign({}, this.result)
      this.getLastLiveDevice(peerIds)
      this.show = true
    },
    getLastLiveDevice (arr) {
      this.axios.post('/ccp/tvucc-device/device/getDeviceLastLivePidMsg', arr, {
        contentType: 'application/json;charset=UTF-8'
      }).then(res => {
        if (res.data.errorCode == '0x0') {
          if (res.data.result.length) {
            const resultArr = res.data.result
            resultArr.forEach(m => {
              if (this.allSourceData[m.peerId] && m.livePid) {
                this.allSourceData[m.peerId].lastLiveName = m.peerName
              }
            })
            this.result.allSourceData = this.allSourceData
            this.result = Object.assign({}, this.result)
          }
        }
      }).catch(() => {})
    },
    // 更新缩略图
    thumbnailUpdate () {
      let peerIds = []
      this.curDeviceList.map(v => {
        if (v && v.status != 2 || v.type == 'X') peerIds.push(v.peerId)
        if (v && v.status == 2 && v.type != 'X') peerIds.push(v.livePeerId)
      })
      if (!peerIds.length) return
      this.axios.post(`${location.protocol}//${this.$store.state.common.baseUrl.blobUrl.split('/')[0]}/thumbnailVersion`, peerIds, { headers: { 'Content-Type': 'application/json;charset=UTF-8' } })
        .then(res => {
          this.imgUrlTime.last = this.imgUrlTime.current
          this.imgUrlTime.current = res.data
        }).catch(() => {})
    },
    sendWebsocket (data) {
      var sendParam = []
      this.peerIds = []
      this.curDeviceList = []
      if (data.list) {
        data.list.forEach(item => {
          sendParam.push(item.peerId)
          this.curDeviceList.push(item)
        })
      }
      this.peerIds = sendParam
      if (this.$createWebsocket.pageRequest) {
        this.$createWebsocket.pageRequest('stop', 'taskInfo_Home_S') // 停止websocket
      }
      this.pageRequest.send({
        message: {
          type: 'taskInfo',
          module: 'taskInfo_Home_S',
          data: { peerIds: this.peerIds.join(',') }
        },
        time: 1000,
        key: 'taskInfo_Home_S',
        success: res => {
          var results = res.result
          this.$store.state.packList = results
          this.liveRids = []
          this.curDeviceList = JSON.parse(JSON.stringify(results))
          if (results.length > 0) {
            var thisSoureceData = []
            results.map(item => {
              // live的rid
              if (item.status == 2 && item.type.toLowerCase() != 'r') {
                if (item.gridLivePeerId != null) {
                  item.gridLivePeerId.split(';').map((v, i) => {
                    this.liveRids.push(v)
                  })
                } else {
                  this.liveRids.push(item.livePeerId)
                }
              }
              // 处理记录最后一次Live的R信息
              const listFilter = this.allSourceData[item.peerId]
              if (listFilter) item.lastLiveName = listFilter.lastLiveName
              thisSoureceData[item.peerId] = item
            })
            this.result.allSourceData = this.ifOpenMediaService && this.shareData.RInfo && this.shareData.RInfo.length > 0 ? handleRlist(this.shareData, thisSoureceData, 'S') : thisSoureceData
            this.allSourceData = this.ifOpenMediaService && this.shareData.RInfo && this.shareData.RInfo.length > 0 ? handleRlist(this.shareData, thisSoureceData, 'S') : thisSoureceData
            this.result = Object.assign({}, this.result)
            this.btn = true
          }
          let newParam = []
          if (this.result.list) {
            this.result.list.forEach(item => {
              newParam.push(item.peerId)
            })
            newParam.push(this.$store.state.common.currentRid)
          }
          this.peerIds = newParam
          var taskInfoMsg = {
            type: 'taskInfo',
            module: 'taskInfo_Home_S',
            data: { peerIds: this.peerIds.join(',') }
          }
          this.pageRequest.sendMsgs.taskInfo_Home_S.message = taskInfoMsg
          // }
        }
      })
    }
  },
  beforeDestroy () {
    if (this.imgTimer) {
      clearInterval(this.imgTimer)
    }
    if (this.$createWebsocket.pageRequest) {
      this.$createWebsocket.pageRequest('stop', 'taskInfo_Home_S') // 停止websocket
    }
    if (this.$createWebsocket.pageRequest) {
      this.$createWebsocket.pageRequest('stop', 'taskInfo_T_R_S') // 停止websocket
    }
    if (this.$createWebsocket.pageRWebsocket) {
      this.$createWebsocket.pageRWebsocket('stop', ['receiverData']) // 停止websocket
    }
  },
  beforeRouteLeave (to, from, next) {
    Bus.$off('openListWebsocket')
    next()
  }
}
</script>
<style lang="less" scoped>
.content {
  /*width: 99%;*/
  /*padding-left: 0.5%;*/
  height: 100% !important;
  max-width: 1458px;
  margin: 0 auto;
  /*padding-left: 0.5%;*/

  .listBox {
    height: calc(100% - 130px);
    //zoom: 71%;
  }
}
.mediaContent {
  overflow-y: scroll;
  overflow-x: hidden;
}
.mediaContent::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 0; /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}

.mediaContent::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  background-color: rgba(144, 147, 153, 0.2);
  opacity: 0.7;
}

.mediaContent::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  border-radius: 0;
  background: none;
}
</style>
